<template>
  <div class="couserView">
    <div v-if="productLine != 3" class="typeRadio">
      <!-- <div
        class="item"
        :class="search.examType == 1 ? 'on' : ''"
        @click="rideo(1)"
      >
        课程测试
      </div> -->
      <!--  <el-divider direction="vertical"></el-divider>
      <div class="item" :class="search.examType == 2 ? 'on' : ''" @click="rideo(2)">套餐题库</div> -->
    </div>
    <!-- 课程资料 -->
    <div v-if="search.examType == 1 && productLine != 3 && ifNew" v-loading="loading">
      <el-collapse v-model="openIndex" accordion @change="handleChange">
        <el-collapse-item v-for="(item, index) in yearsList" v-show="item.newLists && item.newLists.length > 0"
          :key="index" :name="index">
          <template slot="title">
            <div class="title">
              <div class="shu" />
              <div class="title-name">
                {{ item.kcName }}
              </div>
              <div class="radio-area" @click.stop>
                <el-radio-group v-model="item.year" class="redRadio" @change="tabChange(item)">
                  <el-radio v-for="(chil, chilIndex) in item.chil"
                    v-show="chil.list[chilIndex] && chil.list[chilIndex].contactList.length > 0" :key="chilIndex"
                    :label="chil.year">
                    {{ chil.year }}</el-radio>
                </el-radio-group>
              </div>
            </div>
            <div class="fenge" />
          </template>
          <!-- 按章列表 -->
          <el-collapse v-model="openChil" class="docList" accordion>
            <el-collapse-item v-for="(thisList, thisindex) in item.thisList" v-show="thisList.contactList.length > 0"
              :key="thisindex" class="item" :name="thisindex">
              <template slot="title">
                <div class="docList-title w-b-100">
                  <div class="flex-c">
                    <div class="docList-type">
                      {{ liveEnum[thisList.type] }}
                    </div>
                    <div class="ellipsis">
                      {{ thisList.title }}
                    </div>
                  </div>
                  <div class="tips">
                    共
                    {{
                      thisList.type == 1
                      ? thisList.liveshowplaybackList.length
                      : thisList.list.length
                    }}章
                  </div>
                </div>
              </template>
              <div class="examPack">
                <div v-if="thisList.contactList && thisList.contactList.length > 0" class="list">
                  <div v-for="(item, index) in thisList.contactList" :key="index" class="item" @click="tabId = item.id">
                    <img class="item-image" src="@/assets/img/study/pager.png">
                    <div class="item-content w-650">
                      <div class="item-title ellipsis">{{ item.sjName }}</div>
                    </div>
                    <div class="keep-learn keep-check" @click="
                      answerClick(
                        item.cContactid,
                        item.sjName,
                        2,
                        thisList.type,
                        thisList.id,
                        item.id,
                        item.seeNum
                      )
                      ">
                      开始答题
                    </div>
                  </div>
                </div>
                <div v-if="!thisList.contactList || thisList.contactList.length == 0
                  " class="nothing">
                  <!-- <img class="nothing-img" src="@/assets/img/study/noLearn.png" /> -->
                  <div class="nothing-text">暂无内容哦~</div>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </el-collapse-item>
      </el-collapse>
      <div v-if="!yearsList || yearsList.length == 0" class="nothing">
        <!-- <img class="nothing-img" src="@/assets/img/study/noLearn.png" /> -->
        <div class="nothing-text">暂无内容哦~</div>
      </div>
    </div>
    <div v-if="search.examType == 2 || productLine == 3" class="">
      <!-- 试卷包 -->
      <div v-if="list && list.packages.length > 0" class="list-two">
        <div v-for="(item, index) in list.packages" :key="index" class="item" @click="tabActive(item)">
          <img class="item-img" src="@/assets/img/study/pagerBank.png">
          <div class="item-content">
            <div class="item-text twoEllipsis">{{ item.name }}</div>
            <div class="item-num">
              共{{ item.paperNum ? item.paperNum : 0 }}套试卷
            </div>
          </div>
          <div class="keep-learn fles-shrink" @click.stop="checkClick(item)">
            查看试卷
          </div>
        </div>
      </div>
      <!-- 试卷 -->
      <div v-if="list && list.paperList.length > 0" class="examPack zhuanshu">
        <div class="list">
          <div v-for="(item, index) in list.paperList" :key="index" class="item" @click="tabId = item.id">
            <img class="item-image m-l-20 m-r-32-i" src="@/assets/img/study/pager.png">
            <div class="item-content">
              <div class="item-title twoEllipsis">{{ item.name }}</div>
              <!--     <div class="item-tips">
                {{ item.status == 1 ? "已购买" : "￥" + item.paperPrice }}
              </div> -->
            </div>
            <div class="keep-learn" @click="answerClick(item.id, item.name, 4, 0)">
              开始答题
            </div>
          </div>
        </div>
      </div>
      <div v-if="!list || (list.paperList.length == 0 && list.packages.length == 0)
        " class="nothing">
        <!-- <img class="nothing-img" src="@/assets/img/study/noLearn.png" /> -->
        <div class="nothing-text">暂无内容哦~</div>
      </div>
      <el-pagination v-if="total > 0" class="redPage small" :current-page="pageNum" :page-size="pageSize"
        layout="prev, next" :total="total" @prev-click="toprev" @next-click="tonext" />
    </div>
  </div>
</template>
<script>
import Bus from '@/api/bus'
import Vue from 'vue'
import { liveEnum } from '@/api/emun'
import { Know } from '@/api/know'
const know = new Know()
import { getInfo } from '@/api/cookies'
import chater from '@/components/Know/course/chater.vue'
import { AnswerClient } from '@/api/task/answerClient'
const answerClient = new AnswerClient()
export default {
  components: { chater },
  props: ['classesId', 'IsPurchase', 'productLine', 'cardCode', 'direction'],
  data() {
    return {
      liveEnum: liveEnum,
      userInfo: null,
      search: {
        examType: 1 // 1试卷包2：试卷
      },
      list: {
        packages: [],
        paperList: []
      },
      newYear: [],
      yearsList: [], // 年份列表
      openIndex: 0,
      openChil: 0,
      ifNew: true,
      total: 0,
      pageNum: 1,
      pageSize: 5,
      loading: false
    }
  },
  async created() {
    this.userInfo = getInfo()
    await this.getList()
    this.yearsList.filter(async (item, index) => {
      item.newLists = []
      item.chil.filter(async (itemss) => {
        await this.collapseChange(itemss.year, item.kId).then((res) => {

          itemss.list = res
          res.filter((items) => {
            if (items.contactList.length > 0) {
              item.newLists.push(items.contactList)
            }
          })
        })
        this.$forceUpdate()
      })
    })
  },
  methods: {
    /* 搜索 */
    searchClick() {
      this.list = { packages: [], paperList: [] }
      this.yearsList = []
      this.getList()
    },
    rideo(val) {
      this.search.examType = val
      this.searchClick()
    },
    // 获取列表
    async getList() {
      if (this.productLine == 3) {
        /* 专属试卷 */
        if (this.direction) {
          const data = {
            classesId: this.classesId,
            coursePackageId: this.direction,
            stuId: this.userInfo ? this.userInfo.id : null
          }
          know.selectClassesCoursePaper(data).then(res => {
            this.list = res.data
          })
        } else {
          know.selectClassesPaper(this.classesId).then((res) => {
            this.pageList = res.data
            this.total = this.pageList.packages.length + this.pageList.paperList.length
            this.getShowList()
          })
        }
      } else {
        this.ifNew = false
        await know
          .classesTable(
            this.classesId,
            this.userInfo && this.userInfo.id ? this.userInfo.id : undefined
          )
          .then((res) => {
            if (res.code == 0) {
              this.yearsList = res.data.array

              for (const item of this.yearsList) {
                if (this.productLine == '2') {
                  item.chil = []
                  for (const year of item.yearNames) {
                    item.chil.push({ year: year, list: [] })
                  }
                  item.year = item.yearNames[0]
                }
                /* this.tabChange(item); */
              }
            }
          })
        // for (let index in this.yearsList) {
        //   let item = this.yearsList[index];
        //   for (let chilIndex in item.chil) {
        //     let chil = item.chil[chilIndex];
        //     await this.collapseChange(chil.year, item.kId).then((res) => {
        //       chil.list = res.filter((fil) => {
        //         return fil.contactList && fil.contactList.length > 0;
        //       });
        //     });
        //   }
        //   item.chil = item.chil.filter((fil) => {
        //     return fil.list && fil.list.length > 0;
        //   });
        // }
        // this.yearsList = this.yearsList.filter((item) => {
        //   return item.chil && item.chil.length > 0;
        // });
        // for (let item of this.yearsList) {
        //   item.year = item.chil[0].year;
        //   this.tabChange(item);
        // }
        this.handleChange(0)
        this.$nextTick(() => {
          this.ifNew = true
        })
      }
    },
    getShowList() {
      if (this.total > this.pageSize) {
        const packages = this.pageList.packages.slice((this.pageNum - 1) * this.pageSize, this.pageNum * this.pageSize)
        let paperList = []
        if (packages.length == 0) {
          const l = this.pageList.packages.length
          paperList = this.pageList.paperList.slice((this.pageNum - 1) * this.pageSize - l, this.pageNum * this.pageSize - l)
        } else {
          paperList = this.pageList.paperList.slice(0, this.pageSize - packages.length)
        }
        this.list = {
          packages: packages,
          paperList: paperList
        }
      } else {
        this.list = this.pageList
      }
    },
    toprev(page) {
      this.pageNum = page
      this.getShowList()
    },
    tonext(page) {
      this.pageNum = page
      this.getShowList()
    },
    handleChange(index) {
      if (index !== '' && index >= 0) {
        const item = this.yearsList[index]
        this.tabChange(item)
      }
    },
    /* 课程展开  */
    async collapseChange(year, kId) {
      const data = []
      this.loading = true
      await know
        .classesContactList(
          this.classesId,
          this.productLine == '2' ? year : undefined,
          kId
        )
        .then((res) => {
          this.loading = false
          if (res.code == 0) {
            for (const item of res.data.audio) {
              item.type = 3
              data.push(item)
            }
            for (const item of res.data.face) {
              item.type = 5
              data.push(item)
            }
            for (const item of res.data.imageText) {
              item.type = 4
              data.push(item)
            }
            for (const item of res.data.live) {
              item.type = 1
              data.push(item)
            }
            for (const item of res.data.video) {
              item.type = 2
              data.push(item)
            }
          }
        })
        .catch(() => {
          this.loading = false
        })
      return data
    },
    /* 年份选择 */
    async tabChange(item) {
      item.thisList = []
      await this.collapseChange(item.year, item.kId).then((res) => {
        item.thisList = res
      })
      this.$forceUpdate()
    },
    /* 试卷包查看 */
    checkClick(item) {
      const userInfo = getInfo()
      if (!userInfo) {
        Vue.prototype.goLoginView(false)
        return
      }
      /*     if (this.IsPurchase) { */
      this.$router.push(
        `/all/examPack?packId=${item.id}&classId=${this.classesId}&IsPurchase=${this.IsPurchase}`
      )
      /* } else {
        this.$notify({
          title: "错误",
          message: "未购买，无法查看, 请先去购买",
          type: "error",
          duration: 2000,
        });
      } */
    },
    /* 试卷 */
    answerClick(item, name, examType, type, courseId, showId, seeNum) {

      let thisType = null
      switch (type) {
        case 0:
          thisType = 1
          break
        case 1:
          thisType = 2
          break
        case 2:
          thisType = 3
          break
        case 3:
          thisType = 7
          break
        case 4:
          thisType = 8
          break
        case 5:
          thisType = 10
          break
        default:
          break
      }
      answerClient.selectPaperSubjectList(item).then((res) => {
        if (res.code == 0) {
          Vue.prototype.goExam(
            name,
            item,
            examType,
            1,
            this.IsPurchase,
            res.data.paper.seeNum,
            courseId,
            showId,
            this.classesId,
            this.cardCode
          )
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.couserView {
  padding-bottom: 20px;
  background: #fff;
}

.typeRadio {
  margin: 24px 32px 12px;
  display: flex;

  .el-divider {
    margin: 0px 24px;
  }

  .item {
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 14px;
    cursor: pointer;
  }

  .on {
    color: #ff5e51;
  }
}

.redPage {
  text-align: right;

  &.small {
    margin-top: 10px;

    ::v-deep {

      .btn-prev,
      .btn-next {
        width: 25px;
        height: 25px;
        padding: 0 6px;
      }
    }
  }
}

/deep/.el-collapse {
  border: none !important;

  .redRadio {
    display: none;
  }

  .radio-area {
    display: inline-block;
  }

  .is-active {
    .redRadio {
      display: inline-block;
    }
  }

  .el-icon-arrow-right {
    transform: rotate(90deg);
  }

  .is-active.el-icon-arrow-right {
    transform: rotate(270deg);
  }

  .el-collapse-item__header {
    height: 58px;
    padding: 19px 32px 19px 0px;
    border: none !important;
    position: relative;

    .title {
      display: flex;
      height: 20px;
      align-items: center;

      .title-name {
        font-size: 18px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        display: flex;
        align-items: center;
        margin-right: 20px;

        .iconfont {
          color: #333333;
          font-size: 12px;
          margin-right: 9px;
        }
      }

      .shu {
        width: 4px;
        height: 20px;
        background: #fff;
        border-radius: 0px 2px 2px 0px;
        opacity: 1;
        margin-right: 29px;
      }
    }

    .fenge {
      // width: 816px;
      height: 1px;
      background: #eeeeee;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      position: absolute;
      bottom: 0px;
      left: 32px;
      right: 32px;
    }
  }

  .is-active {
    .shu {
      background: #ff5e51 !important;
    }
  }

  .el-collapse-item__content {
    border: none !important;
    padding-bottom: 0px;
  }

  .el-collapse-item__wrap {
    border: none !important;
  }
}

/* 按章列表 */
/deep/ .docList {
  padding: 0px 32px;

  .el-collapse {
    border: none !important;
  }

  .el-collapse-item__header {
    height: 52px;
    background: #f5f7f9;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    padding: 0px 12px;
    margin-top: 12px;
  }

  .docList-type {
    height: 20px;
    background: #ffffff;
    border-radius: 2px 2px 2px 2px;
    border: 1px solid #ff7b1c;
    padding: 0px 6px;
    font-size: 12px;
    font-family: Microsoft YaHei-Regular;
    font-weight: 400;
    color: #ff7b1c;
    line-height: 18px;
    flex-shrink: 0;
    margin-right: 8px;
  }

  .docList-title {
    padding: 20px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;

    .title-text,
    .title {
      font-size: 16px;
      font-family: Microsoft YaHei-Regular;
      font-weight: bold;
      color: #333333;
      line-height: 14px;
    }

    .flex-c {
      width: calc(100% - 100px);
    }

    .tips {
      font-size: 12px;
      font-family: Microsoft YaHei-Regular;
      font-weight: 400;
      color: #999999;
      line-height: 12px;
      margin-right: 17px;
      flex-shrink: 0;
    }

    .iconfont {
      font-size: 16px;
      width: 25px;
      height: 25px;
      transform: rotate(-90deg);
      display: inline-block;
      transition: transform 0.4s;
    }
  }

  .open {
    .iconfont {
      transform: rotate(90deg);
      display: inline-block;
      transition: transform 0.4s;
    }
  }

  .uni-collapse-cell__title-text {
    border: none !important;
  }

  .uni-collapse-cell--open {
    .uni-collapse-cell__title-text {
      border: none !important;
    }
  }
}

/* 试卷列表 */
.examPack {
  .list {
    width: 100%;
    padding: 0px;
    background: #ffffff;

    .item {
      position: relative;
      height: 112px;
      border-bottom: 1px solid #eee;
      display: flex;
      align-items: center;

      .item-image {
        width: 60px;
        height: 80px;
        flex-shrink: 0;
        background: #f7f8f9;
        margin-right: 12px;
      }

      .item-content {
        height: 80px;
        width: 100%;

        .item-title {
          font-size: 16px;
          font-family: Microsoft YaHei-Regular;
          font-weight: 400;
          color: #333333;
          line-height: 24px;
          width: 100%;
        }
      }

      .keep-learn {
        flex-shrink: 0;
      }
    }
  }
}

.zhuanshu {
  .item {
    padding: 24px 32px !important;
  }
}

.list-two {
  padding: 0px 32px;
  /* height: 148px; */
  background: #fff;

  .item {
    border-bottom: 1px solid #eee;
    border-radius: 0px;
    height: 148px;
    padding: 0px;
    margin-bottom: 0px;

    .item-content {
      height: 100px;
    }
  }
}

.w-650 {
  width: 650px !important;
}
</style>
