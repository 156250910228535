<template>
  <div class="couserView">
    <!-- 课程资料 -->
    <div v-if="ifNew" v-loading="loading">
      <el-collapse v-model="openIndex" accordion @change="collapseChange">
        <el-collapse-item
          v-for="(item, index) in yearsList"
          :key="index"
          :name="index"
        >
          <template slot="title">
            <div class="title">
              <img
                style="width: 13px; margin-right: 10px"
                src="@/assets/img/partner/tips2.png"
                alt=""
              >
              <div class="title-name">
                {{ item.kcName }}
              </div>
            </div>
            <div class="fenge" />
          </template>
          <!-- 按章列表 -->
          <el-collapse v-model="openChil" class="docList" accordion>
            <el-collapse-item
              v-for="(thisList, thisindex) in courseList"
              v-show="thisList.contactList.length > 0"
              :key="thisindex"
              class="item"
              :name="thisindex"
            >
              <template slot="title">
                <div class="docList-title w-b-100">
                  <div class="flex-c">
                    <div class="docList-type">
                      {{ liveEnum[thisList.type] }}
                    </div>
                    <div class="ellipsis">
                      {{ thisList.title }}
                    </div>
                  </div>
                  <div class="tips">
                    共
                    {{
                      thisList.type == 1
                        ? thisList.liveshowplaybackList.length
                        : thisList.list.length
                    }}章
                  </div>
                </div>
              </template>
              <div class="examPack">
                <div
                  v-if="thisList.contactList && thisList.contactList.length > 0"
                  class="list"
                >
                  <div
                    v-for="(item, index) in thisList.contactList"
                    :key="index"
                    class="item"
                    @click="tabId = item.id"
                  >
                    <div>
                      <img
                        class="item-image"
                        src="@/assets/img/study/pager.png"
                      >
                      <div class="item-content w-650">
                        <div class="item-title ellipsis">{{ item.sjName }}</div>
                      </div>
                    </div>
                    <div
                      class="keep-learn keep-check"
                      @click="
                        answerClick(
                          item.cContactid,
                          item.sjName,
                          2,
                          thisList.type,
                          thisList.id,
                          item.id,
                          item.seeNum
                        )
                      "
                    >
                      开始答题
                    </div>
                  </div>
                </div>
                <div
                  v-if="
                    !thisList.contactList || thisList.contactList.length == 0
                  "
                  class="nothing"
                >
                  <!-- <img class="nothing-img" src="@/assets/img/study/noLearn.png" /> -->
                  <div class="nothing-text">暂无内容哦~</div>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </el-collapse-item>
      </el-collapse>
      <div v-if="!yearsList || yearsList.length == 0" class="nothing">
        <div class="nothing-text">暂无内容哦~</div>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from '@/api/bus'
import Vue from 'vue'
import { liveEnum } from '@/api/emun'
import { Know } from '@/api/know'
const know = new Know()
import { getInfo } from '@/api/cookies'
import chater from '@/components/Know/course/chater.vue'
import { AnswerClient } from '@/api/task/answerClient'
const answerClient = new AnswerClient()
export default {
  components: { chater },
  props: {
    // 课程id
    classesId: {
      type: String
    },
    // 是否购买
    IsPurchase: {
      type: Boolean,
      default: false
    },
    // 1 普通线 2 精品线  3 专属线 4 普通线加精品线
    productLine: {
      type: String
    },
    // 课程包id
    direction: {}
  },
  data() {
    return {
      liveEnum: liveEnum,
      userInfo: null,
      search: {
        examType: 1 // 1试卷包2：试卷
      },
      list: {
        packages: [],
        paperList: []
      },
      courseList: [], // 课程列表
      yearsList: [], // 数据列表
      currentYear: '', // 当前年份
      yearKc: [], // 年份列表
      openIndex: 0,
      openChil: 0,
      ifNew: true,
      loading: false
    }
  },
  created() {
    this.userInfo = getInfo()
    this.getList()
  },
  methods: {
    // 切换年份
    changeYear(item) {
      this.yearsList = item.yearKcNames
      this.currentYear = item.year
      this.collapseChange(0)
    },
    /* 搜索 */
    searchClick() {
      this.list = { packages: [], paperList: [] }
      this.yearsList = []
      this.getList()
    },
    rideo(val) {
      this.search.examType = val
      this.searchClick()
    },
    // 获取列表
    async getList() {
      this.loading = true
      await know
        .classesContactTableListCourseSys({
          classesId: this.classesId,
          coursePackageId: this.direction,
          userid:
            this.userInfo && this.userInfo.id ? this.userInfo.id : undefined
        })
        .then((res) => {
          if (res.code == 0) {
            this.yearsList = res.data[0].yearKcNames
            this.yearKc = res.data
            this.currentYear = res.data[0].year
          }
        })
      this.collapseChange(0)
      this.$nextTick(() => {
        this.loading = false
      })
    },
    /* 课程展开  */
    async collapseChange(val) {
      if (!val && val !== 0) return
      const item = this.yearsList[val]
      const data = []
      this.loading = true
      await know
        .classesContactList(this.classesId, this.currentYear, item.kId)
        .then((res) => {
          this.loading = false
          if (res.code == 0) {
            for (const item of res.data.audio) {
              item.type = 3
              data.push(item)
            }
            for (const item of res.data.face) {
              item.type = 5
              data.push(item)
            }
            for (const item of res.data.imageText) {
              item.type = 4
              data.push(item)
            }
            for (const item of res.data.live) {
              item.type = 1
              data.push(item)
            }
            for (const item of res.data.video) {
              item.type = 2
              data.push(item)
            }
          }
        })
        .catch(() => {
          this.loading = false
        })
      this.courseList = data
    },
    /* 试卷包查看 */
    checkClick(item) {
      const userInfo = getInfo()
      if (!userInfo) {
        Vue.prototype.goLoginView(false)
        return
      }
      /*     if (this.IsPurchase) { */
      this.$router.push(
        `/all/examPack?packId=${item.id}&classId=${this.classesId}&IsPurchase=${this.IsPurchase}`
      )
      /* } else {
        this.$notify({
          title: "错误",
          message: "未购买，无法查看, 请先去购买",
          type: "error",
          duration: 2000,
        });
      } */
    },
    /* 试卷 */
    answerClick(item, name, examType, type, courseId, showId, seeNum) {
      console.log(this.IsPurchase, 'IsPurchase')
      let thisType = null
      switch (type) {
        case 0:
          thisType = 1
          break
        case 1:
          thisType = 2
          break
        case 2:
          thisType = 3
          break
        case 3:
          thisType = 7
          break
        case 4:
          thisType = 8
          break
        case 5:
          thisType = 10
          break
        default:
          break
      }
      answerClient.selectPaperSubjectList(item).then((res) => {
        if (res.code == 0) {
          Vue.prototype.goExam(
            name,
            item,
            examType,
            1,
            this.IsPurchase,
            res.data.paper.seeNum,
            courseId,
            showId,
            this.classesId,
            this.cardCode
          )
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.couserView {
  padding-bottom: 20px;
  background: #fff;
  .year_wrap {
    min-height: 84px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-left: 32px;
    padding-top: 24px;
    .year_item {
      width: 95px;
      height: 36px;
      border-radius: 8px;
      background-color: #edf0f7;
      color: #565962;
      text-align: center;
      line-height: 36px;
      margin-right: 24px;
      margin-bottom: 20px;
      cursor: pointer;
    }
    .on {
      border: 1px solid #ff5e51;
      background-color: #fff7f6;
      color: #ff5e51;
      font-weight: bold;
    }
  }
}
.typeRadio {
  margin: 24px 32px 12px;
  display: flex;
  .el-divider {
    margin: 0px 24px;
  }
  .item {
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 14px;
    cursor: pointer;
  }
  .on {
    color: #ff5e51;
  }
}
/deep/.el-collapse {
  border: none !important;
  .redRadio {
    display: none;
  }
  .is-active {
    .redRadio {
      display: inline-block;
    }
  }
  .el-icon-arrow-right {
    transform: rotate(90deg);
  }
  .is-active.el-icon-arrow-right {
    transform: rotate(270deg);
  }
  .el-collapse-item__header {
    height: 58px;
    padding: 20px;
    border: none !important;
    position: relative;
    .title {
      display: flex;
      height: 20px;
      align-items: center;
      .title-name {
        font-size: 18px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        display: flex;
        align-items: center;
        margin-right: 20px;
        .iconfont {
          color: #333333;
          font-size: 12px;
          margin-right: 9px;
        }
      }
      .shu {
        width: 4px;
        height: 20px;
        background: #fff;
        border-radius: 0px 2px 2px 0px;
        opacity: 1;
        margin-right: 29px;
      }
    }
    .fenge {
      // width: 816px;
      height: 1px;
      background: #eeeeee;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      position: absolute;
      bottom: 0px;
      left: 32px;
      right: 32px;
    }
  }
  .is-active {
    .shu {
      background: #ff5e51 !important;
    }
  }
  .el-collapse-item__content {
    border: none !important;
    padding-bottom: 0px;
  }
  .el-collapse-item__wrap {
    border: none !important;
  }
}

/* 按章列表 */
/deep/ .docList {
  padding: 0px 20px;
  .el-collapse {
    border: none !important;
  }
  .el-collapse-item__header {
    width: 812px;
    height: 60px;
    background: #f9f9f9;
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    padding: 0px 20px;
    margin-top: 12px;
  }

  .docList-type {
    height: 20px;
    background: #ffffff;
    border-radius: 2px 2px 2px 2px;
    border: 1px solid #ff7b1c;
    padding: 0px 6px;
    font-size: 12px;
    font-family: Microsoft YaHei-Regular;
    font-weight: 400;
    color: #ff7b1c;
    line-height: 18px;
    flex-shrink: 0;
    margin-right: 8px;
  }
  .docList-title {
    padding: 20px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    .title-text,
    .title {
      font-size: 16px;
      font-family: Microsoft YaHei-Regular;
      font-weight: bold;
      color: #333333;
      line-height: 14px;
    }
    .flex-c {
      width: calc(100% - 100px);
    }
    .tips {
      font-size: 12px;
      font-family: Microsoft YaHei-Regular;
      font-weight: 400;
      color: #999999;
      line-height: 12px;
      margin-right: 17px;
      flex-shrink: 0;
    }
    .iconfont {
      font-size: 16px;
      width: 25px;
      height: 25px;
      transform: rotate(-90deg);
      display: inline-block;
      transition: transform 0.4s;
    }
  }
  .open {
    .iconfont {
      transform: rotate(90deg);
      display: inline-block;
      transition: transform 0.4s;
    }
  }
  .uni-collapse-cell__title-text {
    border: none !important;
  }
  .uni-collapse-cell--open {
    .uni-collapse-cell__title-text {
      border: none !important;
    }
  }
}
/* 试卷列表 */
.examPack {
  .list {
    width: 100%;
    padding: 0px;
    background: #ffffff;
    .item {
      position: relative;
      width: 752.5px;
      height: 77px;
      border-bottom: 1px solid #eee;
      display: flex;
      align-items: center;
      margin-left: 20px;
      justify-content: space-between;
      > div:nth-child(1) {
        display: flex;
        align-items: center;
      }
      .item-image {
        width: 33.28px;
        height: 36.48px;
        flex-shrink: 0;
        background: #f7f8f9;
        margin-right: 12px;
      }
      .item-content {
        height: 37px;
        width: 100%;
        .item-title {
          height: 14px;
          font-size: 14px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #333333;
          line-height: 14px;
        }
      }
      .keep-learn {
        width: 108px;
        height: 36px;
        border-radius: 25px 25px 25px 25px;
        opacity: 1;
        border: 1px solid #2586f5;
        background-color: #fff;
        line-height: 36px;
        text-align: center;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #2586f5;
        flex-shrink: 0;
      }
    }
  }
}
.zhuanshu {
  .item {
    padding: 24px 32px !important;
  }
}
.list-two {
  padding: 0px 32px;
  /* height: 148px; */
  background: #fff;
  .item {
    width: 752.5px;
    height: 77px;
    border-bottom: 1px solid #eee;
    padding: 0px;
    margin-bottom: 0px;
    .item-content {
      height: 77px;
    }
  }
}
.w-650 {
  // width: 650px !important;
}
</style>
