<template>
  <div class="classes-question classes-questions">
    <el-collapse v-model="activeNames" class="kcList" accordion>
      <el-collapse-item v-for="(item, index) in questionName" :key="index" :name="index">
        <template slot="title">
          <div class="title">
            <div class="line" />
            <span>{{ item.name }}</span>
          </div>
        </template>
        <!-- 客观题题库 -->
        <question-item v-if="index == 0" :is-purchase="IsPurchase" :question-index="index"
          :question-list="questionList" />
        <!-- 易错错题库 -->
        <question-item v-else-if="index == 1" :is-purchase="IsPurchase" :question-index="index"
          :question-list="fallibleQuestionList" class="qu_item" />
        <!-- 个人错题库 -->
        <!-- 未购买不展示个人错题库 -->
        <question-item v-else-if="index == 2" :is-purchase="IsPurchase" :question-index="index"
          :question-list="IsPurchase ? myWrongQuestionList : []" class="qu_item" />
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import {
  getClassesQuestionList,
  getAllFallibleQuestion,
  getMyWrongQuestion,
} from "@/api/question";
import questionItem from "./question-itemPartner.vue";
import { getInfo } from "@/api/cookies";
export default {
  components: {
    questionItem,
  },
  props: ["classesId", "IsPurchase"],
  data() {
    return {
      questionName: [
        { name: "客观题题库" },
        { name: "易错错题库" },
        { name: "个人错题库" },
      ],
      userInfo: {},
      activeNames: [0],
      questionList: [],
      fallibleQuestionList: [],
      myWrongQuestionList: [],
    };
  },
  async created() {
    this.userInfo = getInfo();
    console.log(this.userInfo);
    // 客观题题库
    const { data } = await getClassesQuestionList({
      classesId: this.classesId,
      userId: this.userInfo && this.userInfo.id,
    });
    // 易错错题库
    const { data: data2 } = await getAllFallibleQuestion({
      classesId: this.classesId,
      userId: this.userInfo && this.userInfo.id,
    });
    // 个人错题库
    const { data: data3 } = await getMyWrongQuestion({
      classesId: this.classesId,
      userId: this.userInfo && this.userInfo.id,
    });
    this.questionList = data || [];
    this.fallibleQuestionList = data2 || [];
    this.myWrongQuestionList = data3 || [];
  },
};
</script>

<style lang="less" scoped>
.classes-question {
  padding-bottom: 31px;
  height: 547px;
  overflow-y: auto;
  width: 625px;

  border-radius: 10px 10px 10px 10px;

  // background: #ffffff;
  /deep/ .el-collapse {
    border: none;

    .el-collapse-item__header {
      border: none;
      border-radius: 10px 10px 0 0;
    }

    .el-collapse-item__wrap {
      border-bottom: #f5f5f5 1px solid;
      border-radius: 0 0 10px 10px;
    }

    // .el-icon-arrow-right {
    //   transform: rotate(90deg);
    // }

    // .is-active.el-icon-arrow-right {
    //   transform: rotate(270deg);
    // }

    .el-collapse-item {
      margin-bottom: 20px;
    }
  }

  .title {
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    display: flex;
    align-items: center;

    .line {
      width: 16px;
      height: 16px;
      background: #ff5e51;
      border-radius: 2px 2px 2px 2px;
      margin-right: 9px;
      display: inline-block;
      background: url('~@/assets/img/partner/viptk.png');
      background-size: 100% 100%;
      margin-left: 20px;
    }
  }
}

/deep/ .el-collapse-item__wrap {
  margin-bottom: 16px;
}

::v-deep .el-icon-arrow-right:before {
  content: "展开  \e6df";
}

::v-deep .el-icon-arrow-right.is-active:before {
  content: "收起  \e6e1";
}

::v-deep .el-icon-arrow-right.is-active {
  transform: rotate(0deg);
}

::v-deep .el-collapse-item__arrow {
  min-width: 55px;
}
</style>


